<template>
	<el-row class="main_MyPay">
		<el-row class="main_MyPay-content">
			<el-col style="border-bottom: 1px solid #ccc; height: 72px; line-height: 72px; text-indent: 30px;"> <span style="color:blue;font-weight:bold;overflow: hidden;"></span>在线充值</el-col>
			
			<el-row :gutter="20" style="padding:0 0 0 10px; ">
				<el-col :span="3" v-for="(item,index) in productType" :key="index" style="margin-top: 34px; margin-left: 32px;">
					<el-button plain :class="{active:changeSelectStyle == index}"  @click="changeStyle(index)" style="position: relative;width:  125px; height: 65px;">{{item}}元</el-button>
				</el-col>
			</el-row>
			<!--<el-row :gutter="20" style="padding:20px 0 0 10px;">-->
			<!--<el-col :span="2" style="widht:50px;"><el-input  @keyup.native="keyup()" v-model="input" placeholder=""> </el-input></el-col>-->
			<!--<el-col :span="1" style="line-height:40px;">元</el-col>-->
			<!--</el-row>-->
			<el-row style="padding:25px 0 0 42px;">
				<el-col>可得:<span style="color:#b7482d; font-size: 26px;">{{bi}}币</span> 应支付 : <span style="color:#b7482d; font-size: 26px;">{{money}}元</span>
					<span style="color:#b7482d;">(¥1元=10币)请选择支付方式</span></el-col>
				<el-col style="margin-top: 20px;color: #b7482d">提示1:充值金币不可提现,充值满¥100元后可开发票。</el-col>
				<el-col style="margin-top: 20px;color: #b7482d">提示2:充值成功后不支持退款。</el-col>
			</el-row>
			<el-row style="padding:36px 0 0 42px;">
				<el-col :span="3" v-for="(item,index) in productPay" :key="index">
					<el-button plain @click="changeStyleType(index)" style="position: relative;widht:150px;">
						<!-- <el-button  plain :class="changeSelectStyleType == index?'active das':'das' "@click="changeStyleType(index)" style="position: relative;widht:150px;"> -->
						<img :src="item.urlImg" alt="">
						{{item.name}}
					</el-button>
				</el-col>
			</el-row>
			<el-dialog :title="zhifu_title" :visible.sync="dialogVisible_zhifu" width="20%" :close-on-click-modal="false"
			 :close-on-press-escape="false" @close='close_zhifu_title'>
				<div style="width: 100%;text-align: center;">
					<vue-qr :text="zhifu_img" :size="200"></vue-qr>
				</div>
				<span slot="footer" class="dialog-footer"></span>
			</el-dialog>
		</el-row>
	</el-row>

</template>


<script>
	import {
		requestesalipay,
		requestcheck_check_orderstate,
		requesteswxpay
	} from "../api/request";
	import vueQr from 'vue-qr'

	export default {
		name: "MyPay",
		components: {
			vueQr
		},
		data() {
			return {
				input: "",
				pickedPay: "",
				changeSelectStyle: 0,
				changeSelectStyleType: '',
				productType: ['50', '100', '300', '500', '1000'],
				productPay: [{
					'name': "",
					'urlImg': require('../assets/pay.png'),
				}, {
					'name': "",
					'urlImg': require('../assets/weixin.png'),
				}],
				bi: 500,
				money: 50,
				dialogVisible_zhifu: false,
				zhifu_img: "",
				zhifu_title:'扫一扫付款'

			}
		},
		methods: {
			changeStyle(index) {
				this.money = this.productType[index]
				this.bi = this.productType[index] * 10
				console.log(index)
				// if(index==1){
				//     this.bi=3000;
				//     this.money=300
				// }else if(index==2){
				//     this.bi=5000;
				//     this.money=500
				// }else if(index==3){
				//     this.bi=10000;
				//     this.money=1000
				// }else if(index==0){
				//     this.bi=1500;
				//     this.money=150
				// }
				this.changeSelectStyle = index;
				this.input = null;
			},
			close_zhifu_title(){
				clearTimeout(this.setmimi);
			},
			changeStyleType(index) {
				console.log(index)
				this.changeSelectStyleType = index;
				if (this.money <= 0) {
					this.$message.error('充值金额不能为0');
				} else {
					if(index==0){
						this.zhifu(this.money,index)
					}else if(index==1){
						this.zhifu(this.money,index)
					}
				}

			},
			zhifu(e,index) {
				let _this = this;
				let info = {
					total_amount: e,
					subject: "支付购买金币",
				}
				this.dialogVisible_zhifu = true;
				if(index==0){
					
					this.zhifu_title='支付宝扫描支付';
					requestesalipay(info).then(res => {
						_this.zhifu_img = res.ret.qrcode_url;
						this.setmimi = setInterval(function() {
							console.log('-1---------------------1-')
							let info1 = {
								out_trade_no: res.ret.out_trade_no,
								total_amount: e,
								subject: "支付购买金币",
							}
							requestcheck_check_orderstate(info1).then(res => {
								if (res.code == 0) {
									_this.$message({
										message: '恭喜你支付成功',
										type: 'success'
									});
									localStorage.setItem("zhifu", 0);
									_this.dialogVisible_zhifu = false
									_this.$router.push("/MyWallet")
									clearTimeout(_this.setmimi);
								}
							})
						}, 1000)
					})
				}else if(index==1){
					this.zhifu_title='微信扫描支付';
					requesteswxpay(info).then(res => {
						_this.zhifu_img = res.ret.qrcode_url;
						this.setmimi = setInterval(function() {
							console.log('----------------------2-')
							let info1 = {
								out_trade_no: res.ret.out_trade_no,
								total_amount: e,
								subject: "支付购买金币",
							}
							requestcheck_check_orderstate(info1).then(res => {
								if (res.code == 0) {
									_this.$message({
										message: '恭喜你支付成功',
										type: 'success'
									});
									localStorage.setItem("zhifu", 0);
									_this.dialogVisible_zhifu = false
									_this.$router.push("/MyWallet")
									clearTimeout(_this.setmimi);
								}
							})
						}, 1000)
					})
				}
				

			},
			
			keyup(e) {
				this.input = this.input.replace(/[^\.\d]/g, '');
				this.input = this.input.replace('.', '');

				this.bi = this.input * 10;
				this.money = this.input
				this.changeSelectStyle = null;
			}
		},
		mounted() {
			if (this.Account.GetSession() == null) {
			  this.$router.push({ path: "#/SignIn" });
			  return;
			}
		}
	}
</script>


<style scoped>
	.main_MyPay {
		background: #f8f8f8;
		height: 1000px;
		padding-top: 25px;
	}

	.main_MyPay-content {
		width: 1395px;
		margin: 0 auto;
		height: 837px;
		background: white;
		box-shadow:0px 0px 6px #c4c4c4;
		border-radius: 5px;
	}

	.das {
		width: 150px;
		height: 60px;
		line-height: 60px;
	}


	.active::before {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		border: 11px solid #009843;
		border-top-color: transparent;
		border-left-color: transparent;
	}
	.active{
		border: 2px solid #009843;
	}
	.active::after {
		content: '';
		width: 1px;
		height: 5px;
		position: absolute;
		right: 3px;
		bottom: 4px;
		border: 2px solid #fff;
		border-top-color: transparent;
		border-left-color: transparent;
		transform: rotate(45deg);
	}
	/deep/.el-dialog__header{
		text-align: center;
	}
	>>>.el-button.is-plain:focus, .el-button.is-plain:hover{
		border: 2px solid #009843;
	}
</style>
